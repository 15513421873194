import { URL } from "@/api/mailLog";
import { mapActions, mapGetters } from "vuex";
import formatDate from "@/plugins/formatDate.js";
import formatFileName from "@/plugins/formatFileName.js";
import loadingInvoice from "@/components/loadingInvoice";
import paginate from "@/components/paginate.vue";
import formatTime from "@/plugins/formatTime.js";
import { TIME_INTERVAL } from "@/consts";
import _ from "lodash";
import UserIcon from "@/components/UserIcon.vue";

export default {
  components: {
    loadingInvoice,
    paginate,
    UserIcon,
  },
  data() {
    return {
      idInterval: null,
      isShowDialog: false,
      dataModal: {},
      dataListMailLog: [],
      paginate: {
        limit: null,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      },
      modalViewFile: false,
      urlFile: null,
      appendFile: null,
      dialogWidth: "50%",
      isLoading: false,
    };
  },

  async created() {
    if (this.getSelectedCompany.id) {
      this.getListMailLog();
      this.setDialogWidth();
    }
  },

  methods: {
    ...mapActions(["loading"]),

    async getListMailLog() {
      this.loading(true);
      this.isLoading = true;

      const params = {};
      params.page = this.paginate.currentPage;
      params.limit = this.paginate.limit;
      params.company_id = this.getSelectedCompany.id;
      const result = await this.$request({
        url: URL.GET_MAIL_LOG,
        params,
      });
      const res = result.data;

      if (res.code == 200) {
        this.dataListMailLog = res.data.data;
        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
        this.$store.dispatch("setInvoiceLogPaginate", this.paginate);

        const is_updating = this.dataListMailLog
          .map((item) => item.is_updating)
          .some((item) => item);

        this.$store.dispatch("setIsUpdating", is_updating);
      }
      this.loading(false);
      this.isLoading = false;
    },

    handleCurrentPageChange($event) {
      this.paginate.currentPage = $event;
      this.getListMailLog();
    },

    handleLimitChange(limit) {
      this.paginate.limit = limit;
      this.paginate.currentPage = 1;
      this.getListMailLog();
    },

    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },

    clickRow(row) {
      this.$refs.tableData.toggleRowExpansion(row);
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "warning-row";
      } else {
        return "success-row";
      }
    },

    showModal(status, ...data) {
      let result = [];
      data.forEach((item) => {
        if (item) {
          item.forEach(function(item_detail) {
            let existed_file = _.find(result, function(_item) {
              return _item.original_file_name == item_detail.original_file_name;
            });
            if(existed_file) {
              existed_file.invoice_2 = item_detail.invoice;
            } else {
              result.push(item_detail);
            }
          });
        }
      });
      if (result.length != 0) {
        this.dataModal = result;
        this.dataModal.status = status;
        this.isShowDialog = true;
      }
    },

    checkErrorLength(data) {
      let result = 0;
      if (data.invalid_tax_code) {
        result += data.invalid_tax_code.length;
      }
      if (data.add_fail) {
        result += data.add_fail.length;
      }
      return result;
    },

    openModalViewFile(file) {
      this.modalViewFile = true;
      this.getPdfFile(file);
    },

    async getPdfFile(file) {
      this.appendFile = file.substr(-3);
      const result = await this.$request({
        responseType: this.appendFile == "xml" ? "" : "blob",
        baseURL: `${process.env.VUE_APP_BACK_END_URL}/files/`,
        url: file,
      });
      if (this.appendFile != "xml") {
        this.urlFile = window.URL.createObjectURL(result.data);
        this.$refs.urlFile.src = this.urlFile;
      } else {
        this.urlFile = result.data;
      }
    },

    formatTime,
    formatFileName,
    formatDate,

    sliceStr(str) {
      if (str.length > 80) {
        return str.substr(0, 75) + "..." + str.substr(-5);
      }
      return str;
    },

    async downloadfile(item) {
      const params = {};
      params.download = true;
      const result = await this.$request({
        baseURL: `${process.env.VUE_APP_BACK_END_URL}/files/`,
        responseType: "blob",
        url: item,
        params,
      });
      this.downloadFile(result.data, item);
    },

    downloadFile(blob, fileName) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = this.formatFileName(fileName);
      a.click();
      window.URL.revokeObjectURL(url);
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;
      if (windowSize < 767) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "60%";
      }
    },
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  computed: {
    ...mapGetters([
      "getSelectedCompany",
      "getIsUpdating",
      "getInvoiceLogPaginate",
    ]),
  },

  watch: {
    getSelectedCompany() {
      this.paginate = {
        ...this.paginate,
        totalItem: null,
        currentPage: 1,
        totalPage: 1,
      };
      this.getListMailLog();
    },

    getIsUpdating(newVal) {
      if (newVal) {
        clearInterval(this.idInterval);
        this.idInterval = setInterval(() => {
          this.getListMailLog();
        }, TIME_INTERVAL);
      } else {
        clearInterval(this.idInterval);
      }
    },

    modalViewFile(newVal) {
      if (!newVal && this.appendFile != "xml") {
        this.$refs.urlFile.src = "about:blank";
      }
    },
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
