<template>
  <div v-if="totalItem > 0" class="flex justify-between items-center mt-3">
    <div class="text-sm">
      ({{ page.startPage }} - {{ page.toPage }}/{{ totalItem }})

      <el-select class="w-20 rounded-lg" size="small" v-model="limitProp" placeholder="Select Page">
        <el-option value="20">20</el-option>
        <el-option value="35">35</el-option>
        <el-option value="50">50</el-option>
      </el-select>

    </div>
    <el-pagination
      v-model:currentPage="displayCurrentPage"
      :page-size="1"
      layout="prev, pager, next"
      pager-count="5"
      :total="totalPage"
      @current-change="handleCurrentChange"
      :hide-on-single-page="true"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 10,
    },
    totalItem: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      page: {
        startPage: null,
        toPage: null,
      },
    };
  },

  computed: {
    ...mapGetters(["selectCurrentCompany"]),
    displayCurrentPage: {
      get: function () {
        return this.currentPage;
      },
      set: function (modifiedCurrentPage) {
        this.handlePage();
        return modifiedCurrentPage;
      },
    },
    limitProp: {
      get() {
        return this.limit;
      },
      set(value) {
        this.$emit("update:limit", parseInt(value));
      },
    },
    totalPageProp: {
      get() {
        return this.totalPage;
      },
    },
  },

  created() {
    this.handlePage();
  },

  methods: {
    handleCurrentChange(val) {
      this.displayCurrentPage = val;
      this.$emit("modifiedCurrentPage", val);
    },

    handlePage() {
      this.page.startPage = (this.currentPage - 1) * this.limit + 1;
      if (this.currentPage == this.totalPage) this.page.toPage = this.totalItem;
      else this.page.toPage = this.currentPage * this.limit;
    },
  },

  watch: {
    currentPage() {
      this.handlePage();
    },
    totalItem() {
      this.handlePage();
    },
    limit() {
      this.handlePage();
    },
  },
};
</script>
