<template>
  <el-tooltip
    :content="user.emailQuanLyTaiKhoan || ''"
    class="user"
    effect="dark"
    placement="bottom"
  >
    <span class="relative icon-email">
      <div class="avatar w-full h-full" v-if="user.avatar_url">
        <img
          :src="user.avatar_url"
          alt="user_avatar"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="default-avatar" v-else>
        {{ cutText(user.emailQuanLyTaiKhoan) }}
      </div>

      <div class="icon-group">
        <span v-if="user.is_itax_admin" title="God" class="icon-key">
          <img src="@/assets/icons/hammer.svg" alt="" class="absolute h-5 w-5"/>
        </span>
        <span v-else-if="user.is_owner" title="Owner" class="icon-key">
          <img src="@/assets/icons/key.svg" alt="" class="absolute h-5 w-5"/>
        </span>
        <span v-if="!user.expired_at && !user.is_itax_admin" title="Chưa kích hoạt" class="icon-activate">
          <img src="@/assets/icons/icon_exclamation.svg" alt="" class="absolute h-6 w-6"/>
        </span>
      </div>
    </span>
  </el-tooltip>
</template>
<script>
export default {
  props: {
    user: {},
  },

  data() {
    return {
      typingTimer: null,
    };
  },

  methods: {
    subtractMinutes(date, minutes) {
      return new Date(date - minutes * 60000);
    },
    isOnline(datetime) {
      return (
        datetime && new Date(datetime) > this.subtractMinutes(new Date(), 1)
      );
    },
    cutText(str) {
      return (str || "").substr(0, 2).toUpperCase();
    },
  },
};
</script>

<style scoped>
.online-mark {
  background: var(--el-color-primary);
  width: 0.65rem;
  height: 0.65rem;
  position: absolute;
  left: 0.8rem;
  top: -0.25rem;
  border-radius: 50%;
  border: 2px solid #fff;
}
.avatar img {
  border-radius: 50%;
}
.icon-key img {
  right: -0.35rem;
}
.bg-super-admin {
  background-color: #5075a8;
}
.bg-super-admin-sub {
  background-color: #5075a8;
}
.bg-admin {
  background-color: #5075a8;
}
.bg-user {
  background-color: #5075a8;
}
.border-Role {
  padding: 0 15px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: not-allowed;
  background: #f5f7fa;
  height: 40px;
}
.icon-email {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  min-width: 40px;
  width: 40px;
  height: 40px;
  font-size: 0.8rem;
  text-align: center;
  background-color: #5075a8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
  border: 2px solid #fff;
}
.icon-activate img {
  top: -0.45rem;
  right: -0.6rem;
}
</style>
