function formatTime(time) {
  var d = new Date(time);
  var timestring =
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + d.getMinutes()).slice(-2) +
    ":" +
    ("0" + d.getSeconds()).slice(-2);
  return timestring;
}

export default formatTime;
