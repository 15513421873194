<el-scrollbar class="height-table" v-loading="isLoading">
  <el-table
    :data="dataListMailLog"
    ref="tableData"
    class="w-full"
    border
    @row-click="clickRow"
    empty-text="Không có dữ liệu"
  >
    <el-table-column
      type="index"
      label="STT"
      :index="indexMethod"
      width="50"
      align="center"
    />

    <el-table-column label="Người dùng" width="200">
      <template #default="props">
        <div class="flex justify-between items-center">
          {{ props.row.is_sync_email || props.row.user.userType == "ITaxAdmin" || props.row.user.userType == "ITaxGeneral" ?
          "Hệ thống" : props.row.user.emailQuanLyTaiKhoan }}
          <UserIcon :user="props.row.user" v-if="props.row.user && props.row.user.userType != 'ITaxAdmin' && props.row.user.userType != 'ITaxGeneral'"/>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Hành động" width="130">
      <template #default="props">
        <div>{{ props.row.is_sync_email ? "Tự động" : "Thủ công" }}</div>
      </template>
    </el-table-column>

    <el-table-column
      prop="company.tenDoanhNghiep"
      label="Công ty"
      min-width="450"
    />

    <el-table-column label="Kết quả" min-width="450">
      <template #default="props">
        <div class="flex justify-between items-center">
          <div>{{ Math.ceil(props.row.detail.total/2) }} tệp</div>
          <div class="loading-invoice" v-if="props.row.is_updating">
            <loading-invoice />
          </div>
          <div v-if="props.row.is_terminate">
            <el-tooltip
              class="item"
              effect="dark"
              content="Hệ thống đọc lỗi"
              placement="left"
            >
              <img
                src="@/assets/icons/icon_exclamation.svg"
                alt=""
                class="w-3 h-3 fill-color"
              />
            </el-tooltip>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="Thời gian" width="180" align="center">
      <template #default="props">
        <div>
          {{`${formatDate(props.row.created_at)}
          ${formatTime(props.row.created_at)}`}}
        </div>
      </template>
    </el-table-column>

    <el-table-column type="expand">
      <template #default="props">
        <div class="flex justify-end">
          <div class="expan-data">
            <div class="mt-2">
              <div v-if="props.row.detail.pdf.total">
                - {{ Math.ceil(props.row.detail.pdf.total/2) }} PDF
              </div>
              <div class="ml-7 grid grid-cols-4 gap-4 mt-2">
                <div class="col-span-1" v-if="props.row.detail.pdf.add_success">
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.pdf.add_success)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.pdf.add_success.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-20">Mới</el-button>
                    </el-badge>
                  </span>
                </div>
                <div
                  class="col-span-1"
                  v-if="props.row.detail.pdf.update_success"
                >
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.pdf.update_success)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.pdf.update_success.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-20">Cập nhật</el-button>
                    </el-badge>
                  </span>
                </div>
                <div class="col-span-1" v-if="props.row.detail.pdf.reported">
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.pdf.reported)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.pdf.reported.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-24">Đã báo cáo</el-button>
                    </el-badge>
                  </span>
                </div>
                <div
                  class="mt-0 col-span-1"
                  v-if="props.row.detail.pdf.invalid_tax_code"
                >
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="
                      showModal(false, props.row.detail.pdf.invalid_tax_code, props.row.detail.pdf.add_fail )
                    "
                  >
                    <el-badge
                      :value="Math.ceil(checkErrorLength(props.row.detail.pdf)/2)"
                      :max="10000"
                      class="item"
                    >
                      <el-button size="mini" class="w-20">Lỗi</el-button>
                    </el-badge>
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div v-if="props.row.detail.xml.total">
                - {{ Math.ceil(props.row.detail.xml.total/2) }} XML
              </div>
              <div class="grid grid-cols-4 gap-4 ml-7">
                <div v-if="props.row.detail.xml.add_success" class="col-span-1">
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.xml.add_success)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.xml.add_success.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-20">Mới</el-button>
                    </el-badge>
                  </span>
                </div>
                <div
                  v-if="props.row.detail.xml.update_success"
                  class="col-span-1"
                >
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.xml.update_success)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.xml.update_success.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-20">Cập nhật</el-button>
                    </el-badge>
                  </span>
                </div>
                <div v-if="props.row.detail.xml.reported" class="col-span-1">
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="showModal(true, props.row.detail.xml.reported)"
                  >
                    <el-badge
                      :value="Math.ceil(props.row.detail.xml.reported.length/2)"
                      :max="10000"
                      class="item"
                      type="primary"
                    >
                      <el-button size="mini" class="w-24">Đã báo cáo</el-button>
                    </el-badge>
                  </span>
                </div>
                <div
                  v-if="props.row.detail.xml.invalid_tax_code"
                  class="col-span-1"
                >
                  <span
                    class="cursor-pointer hover:text-primary"
                    @click="
                      showModal(false, props.row.detail.xml.invalid_tax_code, props.row.detail.xml.add_fail)
                    "
                  >
                    <el-badge
                      :value="Math.ceil(checkErrorLength(props.row.detail.xml)/2)"
                      :max="10000"
                      class="item"
                    >
                      <el-button size="mini" class="w-20">Lỗi</el-button>
                    </el-badge>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</el-scrollbar>

<div>
  <paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
    @update:limit="handleLimitChange"
  />
</div>

<el-dialog v-model="isShowDialog" title="Danh sách" :width="dialogWidth">
  <div class="px-3 py-2 border dialog-table">
    <el-scrollbar max-height="200px">
      <el-table :data="dataModal" stripe style="width: 100%">
        <el-table-column label="Tên file">
          <template #default="scope">
            <p class="hover:text-primary mt-1">
              {{ scope.row.original_file_name }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="Thao tác" width="360">
          <template #default="scope">
            <el-button
              size="mini"
              class="w-btn"
              @click="openModalViewFile(scope.row.file_name)"
            >
              Xem
            </el-button>
            <router-link
              v-if="dataModal.status && scope.row.invoice"
              :to="{ name: 'editInvoices', params: { invoiceId: scope.row.invoice }}"
              target="_blank"
              class="mx-2"
            >
              <el-button size="mini" class="w-btn">Hóa đơn </el-button>
            </router-link>
            <router-link
              v-if="dataModal.status && scope.row.invoice_2"
              :to="{ name: 'editInvoices', params: { invoiceId: scope.row.invoice_2 }}"
              target="_blank"
              class="mx-2"
            >
              <el-button size="mini" class="w-btn">Hóa đơn 2</el-button>
            </router-link>
            <el-button
              size="mini"
              class="w-btn"
              type="danger"
              v-if="dataModal.status && !scope.row.invoice"
            >
              Đã xóa
            </el-button>
            <el-button
              size="mini"
              type="primary"
              @click="downloadfile(scope.row.file_name)"
            >
              Tải file
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
  </div>

  <template #footer>
    <span class="dialog-footer">
      <el-button size="mini" @click="isShowDialog = false">Đóng</el-button>
    </span>
  </template>
</el-dialog>

<el-dialog v-model="modalViewFile" @closed="urlFile = ''" width="80%" top="5vh">
  <div style="height: 70vh" class="overflow-y-auto" v-if="appendFile == 'xml'">
    {{urlFile}}
  </div>
  <iframe v-else class="w-full style-iframe" ref="urlFile" title="File Name">
  </iframe>
  <template #footer>
    <span class="dialog-footer">
      <el-button size="mini" @click="modalViewFile = false">Đóng</el-button>
    </span>
  </template>
</el-dialog>
